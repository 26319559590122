/*Top and bottom scrollbar*/
.large-table-container-3 {
    width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
}

.large-table-container-3 table {}

.large-table-fake-top-scroll-container-3 {
    width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
}

.large-table-fake-top-scroll-container-3 div {
    /* background-color: red; */
    font-size: 1px;
    line-height: 1px;
}