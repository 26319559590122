.order-card-height {
    color: #fff;
    height: 160px;
}

.bg-c-one {
    background: #f2709c;
    background: -webkit-linear-gradient(to right, #ff9472, #f2709c);
    background: linear-gradient(to right, #ff9472, #f2709c);
}

.bg-c-two {
    background: #20DED3;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F6FBA2, #20DED3);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F6FBA2, #20DED3);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-c-three {
    background: #63A4FF;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #83EAF1, #63A4FF);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #83EAF1, #63A4FF);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-c-four {
    background: #D1913C;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFD194, #D1913C);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFD194, #D1913C);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-c-five {
    background: #B621FE;
    background: -webkit-linear-gradient(to right, #1FD1F9, #B621FE);
    background: linear-gradient(to right, #1FD1F9, #B621FE);
}

.bg-c-six {
    background: #F08EFC;
    background: -webkit-linear-gradient(to right, #EE5166, #F08EFC);
    background: linear-gradient(to right, #EE5166, #F08EFC);
}

.bg-c-seven {
    background: #FB7BA2;
    background: -webkit-linear-gradient(to right, #FCE043, #FB7BA2);
    background: linear-gradient(to right, #FCE043, #FB7BA2);
}

.bg-c-eight {
    background: #01BAEF;
    background: -webkit-linear-gradient(to right, #6617CB, #01BAEF);
    background: linear-gradient(to right, #6617CB, #01BAEF);
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card .card-block {
    padding: 25px;
}

.order-card-height i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}


.bodyWrapper::-webkit-scrollbar {
    width: 4px;
    /* width of the entire scrollbar */
}



.bodyWrapper::-webkit-scrollbar-thumb {
    background-color: #CBCFD4;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}


.order-card-e {
    color: #fff;
    height: 140px;
}

.badge-text{
    font-size: 14px;
    /* padding: "15px";       */
    /* width:"10px"; */
    font-weight: 500;
    /* margin-bottom: "8px"; */
    cursor: pointer;  
    color: #D7DBDD;    
}