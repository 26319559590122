html {
    scroll-behavior: smooth;
}

.wrapperForm {
    flex: 1;
    height: 100vh;
    background-image: url(../assets/img/CityBG.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.wrapperInner {
    text-align: center;
    width: 100vw;
    height: 95vh;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

}

.headerTextCont {
    padding: 20px 40px;
    background-image: linear-gradient(to right, #0acffe 0%, #495aff 100%);
}

.headerText {
    font-size: 70px;
    font-weight: 900;
}

.formDesign {
    min-height: vh;
    width: 100vw;
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.arrowIcon {
    font-size: 64px;
    color: red;
    margin: 16px;
}

.arrow {
    width: 60px;
    height: 82px;
    position: absolute;
    /* don't forget set container to relative */
    left: 49%;

    z-index: 999;
}

.arrow path {
    stroke: #050100;
    stroke-width: 2px;
    fill: transparent;
    animation: down 2s infinite;
    cursor: pointer;
}

/* arrow keyframe animation */
@keyframes down {
    0% {
        opacity: 0
    }

    25% {
        opacity: 1
    }

    75% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

/* arrow animation delay */
.arrow path.a1 {
    animation-delay: -1s;
}

.arrow path.a2 {
    animation-delay: -.5s;
}

.arrow path.a3 {
    animation-delay: 0s;
}

.footerCont {    
    padding: 10px;
    background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
    text-align: center;
}