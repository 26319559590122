.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hoverTr {
  font-weight: 500;
}

.hoverTr :hover {
  background-color: #FEFDCE;
  cursor: pointer;
  font-weight: 600;
}

#myBtn {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Fixed/sticky position */
  bottom: 20px;
  /* Place the button at the bottom of the page */
  right: 30px;
  /* Place the button 30px from the right */
  z-index: 99;
  /* Make sure it does not overlap */
  border: none;
  /* Remove borders */
  outline: none;
  /* Remove outline */

  color: white;
  /* Text color */
  cursor: pointer;
  /* Add a mouse pointer on hover */
  padding: 4px;
  /* Some padding */
  border-radius: 10px;
  /* Rounded corners */
  font-size: 24px;
  /* Increase font size */
  transform: rotate(270deg);
  background: #FDC830;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #F37335, #FDC830);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #F37335, #FDC830);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

#myBtn:hover {
  background-color: #555;
  /* Add a dark-grey background on hover */
}

#myBtnBottom {
  margin-top: -6px;
  display: block;
  /* Hidden by default */
  border: none;
  /* Remove borders */
  outline: none;
  /* Remove outline */
  color: white;
  /* Text color */
  cursor: pointer;
  /* Add a mouse pointer on hover */
  padding: 4px;
  /* Some padding */
  border-radius: 10px;
  /* Rounded corners */
  font-size: 24px;
  /* Increase font size */
  transform: rotate(90deg);
  background: #FDC830;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #F37335, #FDC830);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #F37335, #FDC830);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

html {
  overflow: visible !important;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* @media only screen and (max-width: 320px){
  .responsive-font-example{
      font-size: 12em;
  }
} */

/* @media(max-width:200px){
  .responsive-font-example{
       font-size:14px;
      }
    } */

/* Remove number input arrows for Chrome, Safari, Edge, and Opera */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove number input arrows for Firefox */
.no-arrows[type=number] {
  -moz-appearance: textfield;
}