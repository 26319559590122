/* Marquee.css */

.marquee-container {
    background-color: #1D61A4; /* Fixed background color */
    color: #fff;
    padding: 4px;
  }
  
  .marquee-inner-container {
    /* overflow: hidden; */
    /* white-space: nowrap; */
    display: flex;
  }
  
  .marquee-item {
    margin-right: 8px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
  }
  
  .class-div {
    margin-left: 10px;
  }
  
  .cake-icon {
    margin-left: 8px;
    color: #ff7f50;
    text-align: center;
    font-weight: 600;
  }
  
  