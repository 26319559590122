.my-custom-scrollbar {
    position: relative;
    height: 300px;
    overflow: auto;
    }
    .table-wrapper-scroll-y {
    display: block;
    }

    /* .tableFixHead          { overflow: auto; height: 0px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; } */




