.order-card {
    color: #fff;
    height: 140px;
}

.bg-c-blue {
    background: #00d2ff;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3a7bd5, #00d2ff);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3a7bd5, #00d2ff);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.bg-c-green {
    background: #1CD8D2;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #93EDC7, #1CD8D2);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #93EDC7, #1CD8D2);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.bg-c-yellow {
    background: #D1913C;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFD194, #D1913C);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFD194, #D1913C);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.bg-c-pink {

    background: #f2709c;
    background: -webkit-linear-gradient(to right, #ff9472, #f2709c);
    background: linear-gradient(to right, #ff9472, #f2709c);
}


.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card .card-block {
    padding: 25px;
}

.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}


.bodyWrapper::-webkit-scrollbar {
    width: 4px;
    /* width of the entire scrollbar */
}



.bodyWrapper::-webkit-scrollbar-thumb {
    background-color: #CBCFD4;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
}


.order-card-e {
    color: #fff;
    height: 140px;
}

.en-dash-bg-c1-color {
    background: #EF6DA0;
    background: -webkit-linear-gradient(to right, #EE8E6B, #EF6DA0);
    background: linear-gradient(to right, #EE8E6B, #EF6DA0);
}

.en-dash-bg-c2-color {
    background: #09C6F9;
    background: -webkit-linear-gradient(to right, #045DE9, #09C6F9);
    background: linear-gradient(to right, #045DE9, #09C6F9);
}

.en-dash-bg-c3-color {
    background: #E8807F;
    background: -webkit-linear-gradient(to right, #E6C84F, #E8807F);
    background: linear-gradient(to right, #E6C84F, #E8807F);
}

.chart-container1 {
    width: 100%;
    height: 500px;
    /* overflow-x: auto;  */
  }
  
  @media (min-width: 576px) {
    .chart-container1 {
      min-width: 500px;
      /* margin: 0 auto; */
    }
  }
  
