.card-height{
    color: #fff;
    height: 130px;
}

.bg-c-first {
    background: #FE5F75;   
    background: -webkit-linear-gradient(to right, #FC9842, #FE5F75);   
    background: linear-gradient(to right, #FC9842, #FE5F75);  
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
    border: none;
    /* margin-bottom: 30px; */
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card .card-block {
    padding: 20px;
}

.card .card-title{
    text-align: center;
    line-height: 1.4;
}

.badge-title{
    display: flex;
    justify-content: center;  
    /* box-shadow: 10px 10px 5px blue inset;       */
    /* border-top: 1px solid; */     
    /* width:150px; */
    /* background: #FB8085;  
    border-radius: 8px;  
    background: -webkit-linear-gradient(to right, #F9C1B1, #FB8085);    
    background: linear-gradient(to right, #F9C1B1, #FB8085); */
}

.badge-text-first{
    font-size: 14px;
    /* padding: "15px";       */
    /* width:"10px"; */
    font-weight: 500;
    /* margin-bottom: "8px"; */
    cursor: pointer;  
    color: #E1E2E3;    
}
