.HeaderImg{
    width: 500px;
}

@media screen and (max-width: 1000px) {
    .HeaderImg {
        width: 350px;
    }
  }

@media screen and (max-width: 600px) {
.HeaderImg {
    width: 250px;
}
}