#appIconToggle {
    text-shadow: 2px 2px 5px black;
    transition: 0.70s;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    -o-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
#appIconToggle:hover {
    text-shadow: 2px 2px 5px black;
    transition: 0.70s;
    -webkit-animation: spin 0.5s linear infinite;
    -moz-animation: spin 0.5s linear infinite;
    -o-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
}
/* #appIconToggle:hover {
    transition: 0.70s;
    -webkit-transition: 0.70s;
    -moz-transition: 0.70s;
    -ms-transition: 0.70s;
    -o-transition: 0.70s;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
} */